$locations-border-color: $darkest-alto;
$location-item-background-color: $white;
$locations-location-locale-color: $tundora;
$locations-location-content-a-color: $lrs-primary;
$locations-view-link-color: $lrs-primary;
$locations-view-link-background-color: $concrete;

.locations {
  margin: 0 auto;
  padding: 50px 25px;
  max-width: 1200px;
  width: 100%;

  h2 {
    display: block;
    clear: both;
    margin: 0 0 20px;
    padding: 0 0 8px;
    border-bottom: 3px solid $locations-border-color;
  }

  .locations-group {
    margin-bottom: 40px;
  }

  .location-item {
    display: block;

    // display: inline-block;
    // margin-right: 10px;
    margin-bottom: 20px;
    width: 100%;
    background: $location-item-background-color;
    box-shadow: 0 0 5px rgba($black, 0.2);
    @include respond-to(640px) {
      // width: calc((100% - 20px) / 2);
      width: calc((100% - 30px) / 2);
    }
    @include respond-to(960px) {
      width: calc((100% - 40px) / 3);
      // vertical-align: top;
    }

    .location-content {
      padding: 20px;

      .location-locale {
        margin-bottom: 1em;
        color: $locations-location-locale-color;
        font-size: 22px;
      }

      a {
        color: $locations-location-content-a-color;
        font-weight: 500;
      }
    }

    .location-image {
      display: none;
    }

    .view-link {
      display: block;
      padding: 8px 20px;
      background: $locations-view-link-background-color;
      color: $locations-view-link-color;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
    }
  }

  .featured-location {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100% !important;

    .featured-location-image {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      @include respond-to(640px) {
        float: left;
      }

      img {
        display: block;
      }
    }

    .featured-location-content {
      @include respond-to(640px) {
        position: relative;
        float: left;
        padding-bottom: 37px;
        width: calc(100% - 500px);
      }

      .view-link {
        @include respond-to(640px) {
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      }
    }
  }
}

.enhanced-content {
  padding-top: 30px;

  div {
    padding-top: 2px;
    padding-left: 35px;
  }

  .office-box {
    background: url('/themes/LRS/assets/dist/images/office.png') no-repeat 0 0;
  }

  .distributor-box {
    background: url('/themes/LRS/assets/dist/images/distributor.png') no-repeat 0 0;
  }

  a {
    font-weight: bold;
    font-size: 21px;
    line-height: 24px;
  }

  p {
    font-size: 16px !important;
    line-height: 24px;
  }
}
