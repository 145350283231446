// $hp-careers-base-color: $lrs-secondary;

// $hp-careers-heading-color: $white;
// $hp-careers-heading-background-color: $hp-careers-base-color;
// $hp-careers-background-color: darken(saturate(adjust-hue($hp-careers-base-color, -0.0980), 0.0000), 10.0000);
// $hp-careers-division-color: $white;
// $hp-careers-region-color: $white;
// $hp-careers-job-title-color: $white;

// .hp-careers {
//   margin-bottom: 25px;

//   @include respond-to(768px) {
//     float: left;
//     width: calc((((100% - 10px) / 3) * 2) - 1px);
//     margin-right: 10px;
//     margin-bottom: 0;
//   }

//   @include respond-to(1024px) {
//     width: calc(((100% - 10px) / 2) - 1px);
//   }

//   @include respond-to(1300px) {
//     width: calc((100% - 290px) - 1px);
//   }

//   @include respond-to(1920px) {
//     width: calc((100% - 290px) - 1px);
//   }

//   h2 {
//     color: $hp-careers-heading-color;
//     background: $hp-careers-heading-background-color;
//   }

//   .hp-widget-inner {
//     background: rgba($hp-careers-background-color, 0.75);
//   }

//   .posting {
//     position: relative;
//     display: block;
//     padding: 27px 18px 28px;
//     text-align: center;
//     transition: background-color 0.2s ease;

//     @include respond-to(768px) {
//       padding: 29px 56px 30px 25px;
//       text-align: left;
//     }

//     &:nth-of-type(even) {
//       background: rgba($hp-careers-background-color, 0.43);
//     }

//     &::after {
//       position: absolute;
//       top: 50%;
//       right: 20px;
//       display: none;
//       width: 26px;
//       height: 17px;
//       margin-top: -8px;
//       background-image: url('../svg/arrow.svg');
//       background-position: center;
//       background-repeat: no-repeat;
//       background-size: 100%;
//       content: '';
//       opacity: 0.6;
//       transition: opacity 0.2s ease-out;


//       @include respond-to(768px) {
//         display: block;
//       }
//     }

//     &:hover {
//       background-color: rgba($hp-careers-background-color, 0.85);
//       &::after {
//         opacity: 1;
//       }
//     }
//   }

//   .division {
//     display: block;
//     font-size: 13px;
//     line-height: 18px;
//     letter-spacing: 2px;
//     color: $hp-careers-division-color;
//     text-transform: uppercase;
//   }

//   .region {
//     display: block;
//     margin-bottom: 18px;
//     font-size: 13px;
//     font-weight: 800;
//     line-height: 16px;
//     letter-spacing: 2px;
//     color: $hp-careers-region-color;
//     text-transform: uppercase;
//   }

//   .job-title {
//     display: block;
//     font-size: 16px;
//     font-weight: 600;
//     line-height: 18px;
//     color: $hp-careers-job-title-color;
//   }
// }


$hp-careers-base-color: $lrs-secondary;

$hp-careers-heading-color: $white;
$hp-careers-heading-background-color: $hp-careers-base-color;
$hp-careers-background-color: darken(saturate(adjust-hue($hp-careers-base-color, -0.0980), 0.0000), 10.0000);
$hp-careers-widget-h4-color: $white;
$hp-careers-widget-p-color: $white;

// $hp-careers-division-color: $white;
// $hp-careers-region-color: $white;
// $hp-careers-job-title-color: $white;

.hp-careers {

  h2 {
    color: $hp-careers-heading-color;
    background: $hp-careers-heading-background-color;
  }

  .hp-widget-inner {
    background: rgba($hp-careers-background-color, 0.75);
  }


  .hp-widget-content {
    padding: 30px 25px 70px;

    @include respond-to(640px) {
      padding-right: 80px;
    }

    @include respond-to(1024px) {
      padding-right: 40px;
    }
  }

  h3 {
    display: block;
    padding: 0 8px;
    margin: 42px 0 0;
    font-size: 21px;
    font-weight: 700;
    line-height: 24px;
    color: $hp-careers-widget-h4-color;
    text-align: center;

    @include respond-to(640px) {
      padding-left: 0;
      text-align: left;
    }
  }

  p {
    display: block;
    padding: 0;
    margin: 8px 0 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $hp-careers-widget-p-color;
    text-align: center;

    @include respond-to(640px) {
      text-align: left;
    }
  }
}
