/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/

// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';
// Foundation
@import 'foundation/foundation/settings';
@import 'foundation/normalize';
@import 'foundation/foundation';
// Normalize
@import 'vendor/normalize';
// Additional Presets & Variables
@import 'presets/grid-settings';
@import 'presets/helper-classes';

// Vendor
@import 'vendor/accessibility';
@import 'vendor/modal';
@import 'vendor/article-grid';
@import 'vendor/article-columns';
@import 'vendor/cta';

// Layout
@import 'layout/header';
@import 'layout/content';
@import 'layout/footer';
@import 'layout/base';
// Components
@import 'components/general';
@import 'components/typography';
@import 'components/branding';
@import 'components/nav-primary';
@import 'components/nav-secondary';
@import 'components/columns';
// @import 'components/images';
@import 'components/iebar';
// HEADER
@import 'components/page-header';
@import 'components/branding';
@import 'components/nav-global';
@import 'components/nav-primary';
@import 'components/touch-nav-toggle';
@import 'components/search';
@import 'components/nav-departments';
@import 'components/alerts';
// HOMEPAGE
@import 'components/corporate-profile';
@import 'components/homepage-content';
@import 'components/hp-news-widget';
@import 'components/hp-careers-widget';
@import 'components/hp-jobs-widget';
// SUBPAGES
@import 'components/subpage-banners';
@import 'components/nav-subpage';
@import 'components/subpage-content';
@import 'components/webcam-gallery';
@import 'components/forms';
@import 'components/clickd';
@import 'components/error';
@import 'components/locations';
@import 'components/location';
@import 'components/news';
// FOOTER
@import 'components/page-footer';
// Other
@import 'misc';
@import 'shame';