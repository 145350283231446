$errorpage-background-color: $lrs-primary;
$errorpage-content-background-color: $lrs-primary;
$errorpage-content-h1-color: $white;
$errorpage-h2-color: $white;
$errorpage-a-color: $white;
$errorpage-page-footer: darken(desaturate(adjust-hue($errorpage-content-background-color, -0.0710), 0.0332), 14.7059);
$dnnlogin-background-color: $white;
$dnnlogin-border-color: $white;
$dnnlogin-label-color: $white;
$dnnlogin-input-background-color: $white;
$dnnlogin-input-border-color: darken(saturate(adjust-hue($lrs-primary, 0.0859), 6.8702), 7.0588);
$dnnlogin-input-focused-border-color: $tundora;
$dnnlogin-dnnprimaryaction-hover-border-color: $white;

body.errorpage {

  @include respond-to(640px) {
    background-color: $errorpage-background-color;
  }
}

.errorpage {

  .page-header {
    box-shadow: 0 0 4px rgba($black, 0.6);
  }

  .subpage-content,
  .subpage-content-full {
    padding-top: 23px;
    background-color: $errorpage-content-background-color;

    h1 {
      display: block;
      padding: 0;
      margin: 0;
      font-size: 60px;
      font-weight: 600;
      line-height: 60px;
      letter-spacing: -2px;
      color: $errorpage-content-h1-color;
      text-transform: none;
    }

    h2 {
      display: block;
      margin-top: 18px;
      margin-bottom: 32px;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 1px;
      color: rgba($errorpage-h2-color, 0.6);
      text-transform: uppercase;
    }

    p {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0;
      color: $white;
    }

    a {
      color: $errorpage-a-color;
      border-bottom: 1px solid rgba($errorpage-a-color, 0.75);
    }

    .DnnModule-Authentication {
      max-width: 500px;
      margin-top: 18px;
    }

    .dnnLogin {
      padding: 25px 25px 35px;
      margin-top: 18px;
      background: rgba($dnnlogin-background-color,0.25);
      border: 5px solid rgba($dnnlogin-border-color,0.5);
    }

    .LoginPanel {
      position: relative;
      width: 100%;
      padding-right: 0;
    }

    .dnnLogin {

      .dnnFormItem {

        + .dnnFormItem {
          margin-top: 18px;
        }

        &:nth-child(3) {
          float: left;
          width: 50%;
          clear: none;
          margin-top: 23px;
          /*margin: 0;*/

          .dnnFormLabel {
            display: none;
          }
        }

        &:nth-child(4) {
          display: none;
        }

        &:nth-child(5) {
          float: left;
          width: 50%;
          clear: none;
          margin-top: 25px;
          /*margin: 0;*/

          .dnnFormLabel {
            display: none;
          }
        }

        label {
          display: block;
          width: 100%;
          margin-bottom: 2px;
          font-family: $primary-font;
          font-size: 20px;
          font-weight: 700;
          color: $dnnlogin-label-color;
          text-align: left;
        }

        input[type='text'],
        input[type='password'] {
          display: block;
          width: 100%;
          padding: 5px 10px 6px;
          font-size: 24px;
          font-weight: 300;
          background: $dnnlogin-input-background-color;
          border: 1px solid $dnnlogin-input-border-color;
          border-radius: 0;
          box-shadow: none;

          &:focus {
            border-color: $dnnlogin-input-focused-border-color;
          }
        }

        .dnnPrimaryAction {
          display: inline;
          width: auto;
          min-width: auto;
          padding: 0;
          margin: 0;
          font-size: 22px;
          font-weight: 600;
          text-shadow: none;
          background: none;
          border-color: transparent;
          border-style: solid;
          border-width: 0 0 4px 0;
          border-radius: 0;
          box-shadow: none;

          &:hover {
            border-color: rgba($dnnlogin-dnnprimaryaction-hover-border-color,1);
          }
        }

        .dnnSecondaryAction {
          display: none;
          /*display: inline;
          float: right;
          width: auto;
          min-width: auto;
          padding: 0;
          margin: 0 0 0 20px;
          font-size: 22px;
          font-weight: 100;
          text-shadow: none;
          background: none;
          border-color: transparent;
          border-style: solid;
          border-width: 0 0 4px 0;
          border-radius: 0;
          box-shadow: none;

          &:hover {
            border-color: rgba($dnnlogin-dnnprimaryaction-hover-border-color,1);
          }*/
        }

        .dnnLoginRememberMe {
          display: none;
        }

        .dnnLoginActions {
          float: right;

          .dnnActions {
            margin: 0;

            li {

              a {
                display: block;
                width: 100%;
                padding: 0;
                margin: 0;
                font-family: $primary-font;
                font-size: 16px;
                font-weight: 300;
                letter-spacing: 1px;
                text-align: right;
                text-shadow: none;
                text-transform: uppercase;
                background: none;
                border: 0;
                border-bottom: 1px solid transparent;
                border-radius: 0;
                box-shadow: none;

                &:hover {
                  border-bottom-color: rgba($dnnlogin-dnnprimaryaction-hover-border-color,0.75);
                }
              }
            }
          }
        }
      }
    }
  }
}

.errorpage .page-footer{
  border-top-color: $errorpage-page-footer;
}