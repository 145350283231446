$subpage-banner-border-color: $tundora;
$subpage-banner-before-background-color: $black;
$subpage-banner-after-background-color: $white;
$subpage-banner-h2-color: $white;

.subpage-banner {
  position: relative;
  border-bottom: solid 20px $subpage-banner-border-color;
  background-image: url('/themes/LRS/assets/dist/images/fpo-banner-who-we-are.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: $primary-font;
  background-color: #404040;

@include respond-to(1368px) {
    height: 560px;
  }

@include respond-to(960px) {
    height: 420px;
  }

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: block;
    background: rgba($subpage-banner-before-background-color, 0.05);
    content: '';
  }

  &:after {
    position: absolute;
    bottom: 0;
    z-index: 3;
    display: block;
    width: 100%;
    height: 3px;
    background: rgba($subpage-banner-after-background-color, 0.35);
    content: '';
  }

  .careers & {
    border-bottom: none;
  }

  .tagline {
    position: absolute;
    display: none;

    @include respond-to(960px) {
      position: absolute;
      top: 53%;
      z-index: 3;
      display: block;
      padding-right: 50%;
      padding-left: 25px;
      width: 100%;
    }

    p {
      display: block;
      margin: 15px 0;
      width: 100%;
      color: $subpage-banner-h2-color;
      font-weight: 300;
      font-size: 35px;
      line-height: 36px;
    }
  }

  .careers & {
    background-image: url('/themes/LRS/assets/dist/images/bnr-careers.jpg');
  }

  .contact & {
    background-image: url('/themes/LRS/assets/dist/images/bnr-contact.jpg');
  }

  .divisions & {
    background-image: url('/themes/LRS/assets/dist/images/bnr-divisions.jpg');
  }

  .what-we-believe & {
    background-image: url('/themes/LRS/assets/dist/images/bnr-whatwebelieve.jpg');
  }

  .who-we-are & {
    background-image: url('/themes/LRS/assets/dist/images/bnr-whoweare.jpg');
  }

  .lrs-campus-expansion & {
    background-image: url('/themes/LRS/assets/dist/images/bnr-ground breaking.png');
  }
}

// revising subpage banners on careers
.careers-app,
.subscription-center,
.unsubscribe {
  .subpage-banner {
    background-image: none;

@include respond-to(1368px) {
      height: auto;
    }

@include respond-to(960px) {
      height: auto;
    }

    .tagline {
      display: none;
    }
  }
}

.subscription-center,
.unsubscribe {
  .subpage-banner {
    background-image: none;
    border-bottom: none;

    @include respond-to(1368px) {
      height: auto;
    }

    @include respond-to(960px) {
      height: auto;
    }

    .tagline {
      display: none;
    }
  }
}
