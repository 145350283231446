$base-font-size: 100%;
// Breakpoints
$short: 480px;
$tall: 640px;
$grande: 768px;
$venti: 1024px;
$intermezzo: 1220px;
$trenta: 1300px;
$supremo: 1427px;
$maximo: 1920px;

// Fonts
$primary-font: 'Red Hat Text', sans-serif;
$secondary-font: 'Fira Sans Condensed', sans-serif;

// Colors

// Light Grays
$white: #ffffff; // 30+
$alabaster: #fcfcfc; // 1
$christmas-snow: #fafafa; // 1
$white-rice: #f7f7f7; // 1
$wild-sand: #f5f5f5; // 1
$wild-rice: #f4f4f4; // 1
$concrete: #f2f2f2; // 3
$gallery: #ebebeb; // 5
$steel: #e6e6e6; // 2
$mercury: #e5e5e5; // 2
$brutus: #e2e2e2; // 1
$iron: #e1e1e3; // 1
$steel: #e0e0e0; // 2
$alto: #dfdede; // 3
$darker-alto: #d9d9d9; // 3
$darkest-alto: #d7d7d7; // 6
$darkdark-alto: #d6d6d6; // 6
$team-coco: #c0c0c0; // 3
$nobel: #b3b3b3; // 6
$silver-chalice: #a4a4a4; // 1
$dusty-gray: #999999; // 4
$repose-gary: #939393; // 2

// Medium Gray
$lightened-gray: #838383; // 2
$gray: #808080; // 5
$dove-gray: #737373; // 6

// Dark Gray
$darker-dove-gray: #666666; // 3
$scorpion: #606060; // 3
$medium-scorpion: #5c5c5c; // 1
$darker-scorpion: #595959; // 8
$lightest-tundora: #4d4d4d; // 5
$tundora: #404040; // 7
$shafty: #3c3c3c; // 4
$twenty-six: #262626; // 2
$mine-shaft: #222222; // 3
$abyss: #0d0d0d; // 1
$inky: #070707; // 3
$black: #000000; // 12

// Pantone Swatches
$pantone-286c: #0033a0; // Pantone 286 C
$pantone-381c: #cedc00; // Pantone 381 C
$pantone-2995c: #00a9e0; // Pantone 2995 C

// Color Palette
$navy: lighten(desaturate(adjust-hue($pantone-286c, -4.3750), 27.7108), 1.1765); // 17468f
$sky: darken(saturate(adjust-hue($pantone-2995c, 0.2679), 0.0000), 3.9216); // 0099cc
$apple: lighten(desaturate(adjust-hue($pantone-381c, 0.8746), 28.6853), 7.6471); // cddb28

// LRS Colors
$lrs-primary: $navy;
$lrs-secondary: $sky;
$lrs-accent: $apple;

//Tables
$table-border: #d9d9d9;
$table-head-text: white;
$table-head-background: $lrs-primary;
$table-head-border: #d9d9d9;
