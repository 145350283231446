$hp-news-widget-h2-color: $abyss;
$hp-news-widget-h2-background-color: $lrs-accent;
$hp-news-widget-inner-background-color: $white;
$hp-news-widget-division-color: $darker-scorpion;
$hp-news-widget-division-a-hover-color: $lrs-primary;
$hp-news-widget-h4-color: $lrs-primary;
$hp-news-widget-p-color: $dove-gray;
$hp-news-widget-more-color: $lrs-primary;

.hp-news {
  overflow: hidden;
  margin-bottom: 25px;
  @include respond-to(640px) {
    margin-bottom: 45px;
  }
  @include respond-to(1024px) {
    margin-bottom: 0;
  }

  h2 {
    background: $hp-news-widget-h2-background-color;
    color: $hp-news-widget-h2-color;
  }

  .hp-widget-inner {
    background: rgba($hp-news-widget-inner-background-color, 1);
  }

  .hp-widget-content {
    overflow: hidden;
    height: calc(100% - 45px);
  }

  .featured-blog-post {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .featured-image {
    display: none;
    @include respond-to(640px) {
      display: block;
      float: left;
      min-height: 331px;
      width: 150px;
      height: 100%;
      background: url('/themes/LRS/assets/dist/images/fpo-divisions.png');
      background-position: center;
      background-size: cover;
    }
    @include respond-to(1024px) {
      min-height: 1px;
      width: 176px;
    }
  }

  .featured-content {
    padding: 30px 20px 30px;
    @include respond-to(640px) {
      float: left;
      padding-right: 80px;
      width: calc(100% - 150px);
    }
    @include respond-to(1024px) {
      padding-right: 40px;
      width: calc(100% - 176px);
    }
  }

  .division {
    display: block;
    color: $hp-news-widget-division-color;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 13px;
    @include respond-to(640px) {
      text-align: left;
    }

    a {
      color: $hp-news-widget-division-color;
      text-decoration: none;

      &:hover {
        color: $hp-news-widget-division-a-hover-color;
      }
    }
  }

  h4 {
    display: block;
    margin: 23px 0 0;
    padding: 0 8px;
    color: $hp-news-widget-h4-color;
    text-align: center;
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
    @include respond-to(640px) {
      padding-left: 0;
      text-align: left;
    }
  }

  p {
    display: block;
    margin: 8px 0 0;
    padding: 0;
    color: $hp-news-widget-p-color;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    @include respond-to(640px) {
      text-align: left;
    }
  }

  .more {
    display: block;
    margin-top: 12px;
    color: $hp-news-widget-more-color;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    @include respond-to(640px) {
      text-align: left;
    }
  }

  .view-all {
    @include respond-to(640px) {
      clear: both;
    }
  }
}
