$corporate-profile-background-color: #001645;
$corporate-profile-featured-content-before-background-color: $lrs-secondary;
$corporate-profile-featured-content-category-color: $team-coco;
$corporate-profile-featured-content-title-color: $white;
$corporate-profile-featured-content-blurb-color: $wild-rice;
$corporate-profile-featured-content-read-more-color: $white;
$corporate-profile-featured-content-read-more-background-color: $repose-gary;

.corporate-profile {
  display: block;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  background: rgba($corporate-profile-background-color, 0.83);
  @include respond-to(640px) {
    margin-top: 45px;
    margin-bottom: 51px;
  }
  @include respond-to(1024px) {
    margin-top: 59px;
  }

  .featured-content-container {
    position: relative;
    display: block;
    width: 100%;
  }

  .featured-image {
    display: block;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include respond-to(640px) {
      float: left;
      width: 50%;
    }
    @include respond-to(768px) {
      min-height: 276px;
      width: 46%;
    }
    @include respond-to(1024px) {
      min-height: 300px;
    }
    @include respond-to(1300px) {
      min-height: auto;
      width: 39%;
    }
    @include respond-to(1427px) {
      background: none;
    }
    @include respond-to(1920px) {
      width: 860px;
      height: 500px;
    }

    .dnnForm.dnnClear {
      position: relative;
    }

    .image-container {
      position: relative;
      display: block;

      img {
        display: block;
        width: 100%;
        @include respond-to(640px) {
          display: none;
        }
        @include respond-to(1427px) {
          display: block;
        }
      }
    }

    .edit-link {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 80;
    }

    .dnnActions.dnnClear {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
  }

  .featured-content {
    position: relative;
    padding: 32px;
    text-align: center;
    @include respond-to(640px) {
      float: left;
      width: 50%;
      text-align: left;
    }
    @include respond-to(768px) {
      width: 54%;
    }
    @include respond-to(1024px) {
      padding: 0;
    }
    @include respond-to(1300px) {
      width: 61%;
    }
    @include respond-to(1920px) {
      width: calc(100% - 860px);
      // width: 61%;
    }

    &::before {
      position: absolute;
      top: -23px;
      left: calc(50% - 23px);
      display: block;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      background-color: $corporate-profile-featured-content-before-background-color;
      background-image: url('/themes/LRS/assets/dist/images/lrs-corporate-diamond-white.svg');
      background-position: center;
      background-size: 30px 21px;
      background-repeat: no-repeat;
      content: '';
      @include respond-to(640px) {
        top: 20px;
        left: -23px;
      }
      @include respond-to(1920px) {
        top: 56px;
      }
    }

    .featured-content-inner {
      @include respond-to(1024px) {
        padding: 32px 90px 64px 32px;
        height: 100%;
      }
      @include respond-to(1920px) {
        padding-top: 56px;
        padding-left: 35px;
      }
    }

    .category {
      color: $corporate-profile-featured-content-category-color;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 13px;
      font-family: $primary-font;
      @include respond-to(1920px) {
        display: block;
        margin-top: 15px;
      }
    }

    .title {
      display: block;
      margin-top: 22px;
      margin-bottom: 0;
      padding: 0;
      color: $corporate-profile-featured-content-title-color;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 400;
      font-size: 20px;
      font-family: $primary-font;
      line-height: 26px;

      @include respond-to(1024px) {
        font-size: 22px;
      }
      @include respond-to(1275px) {
        margin-top: 40px;
        font-size: 28px;
      }
      @include respond-to(1920px) {
        margin-top: 56px;
      }
    }

    .blurb {
      display: block;
      margin-top: 1px;
      color: rgba($corporate-profile-featured-content-blurb-color, 0.80);
      font-weight: 300;
      font-size: 16px;
      font-family: $primary-font;

      @include respond-to(1024px) {
        margin-top: 8px;
        font-size: 18px;
      }

      @include respond-to(1275px) {
        max-width: 720px;
        font-size: 22px;
      }
      @include respond-to(1920px) {
        margin-top: 18px;
        max-width: 1000px;
        font-size: 28px;
      }
    }
  }

  .read-more {
    display: block;
    clear: both;
    padding: 10px 0 8px;
    background: rgba($corporate-profile-featured-content-read-more-background-color, 0.25);
    color: $white;
    color: $corporate-profile-featured-content-read-more-color;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    transition: background-color 0.2s ease-out;

    &:hover {
      background: rgba($corporate-profile-featured-content-read-more-background-color, 0.4);
    }

    &.read-more-alt {
      position: absolute;
      bottom: 0;
      display: none;
      padding: 14px 0 12px 32px;
      width: 100%;
      text-align: left;
      font-size: 14px;
      @include respond-to(1024px) {
        display: block;
      }
      @include respond-to(1920px) {
        padding-left: 35px;
      }
    }

    &.read-more-full {
      @include respond-to(1024px) {
        display: none;
      }
    }
  }
}
