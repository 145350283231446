.news-search {
  display: none;
}

.news-listings {
  .postItemImg,
  .pubdate {
    display: none;
  }

  .post {
    display: block;
    padding: 0 0 35px;

    .copy {
      padding: 12px 0 20px;
      color: #595959;
      font-size: 20px;
      line-height: 28px;
    }

     + .post {
      padding-top: 30px;
      border-top: 3px solid #d6d6d6;
    }

    .post-read-more {
      font-weight: 400;
      font-size: 20px;
    }
  }
}

.news-detail {
  .post-detail-heading {
    padding-bottom: 30px;
    border-bottom: 3px solid #d6d6d6;
  }

  .pubdate {
    display: none;
  }
}
