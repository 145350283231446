$page-header-background-color: $white;

.page-header {
  position: relative;
  z-index: 100;
  display: block;
  width: 100%;
  padding: 12px 9px;
  background: $page-header-background-color;
  font-family: $primary-font;
  box-shadow: 0 0 3px rgba($black, 0.35);
  transition: top 0.1s ease-out;

  @include respond-to(1350px){
    padding: 17px 15px;
  }

  &.fixed {
    position: fixed;
    top: 0px;
    z-index: 99;
    display: none;
    padding: 6px 15px 5px;
    // -webkit-box-shadow: 1px 2px 2px rgba($black, 0.3);
    box-shadow: 0 0 5px rgba($black, 0.35);

    @include respond-to(1350px){
      display: block;
      padding: 6px 15px 5px;
    }

    &.active {
      top: 0;
      z-index: 99;
    }
  }
}
