.subpage-content.webcam,
.subpage-content-full.webcam {
  background: #ffffff;

  .primary-content {
    padding: 45px 30px 60px 30px;

    .content-pane {
      margin: 0 auto;
      max-width: 1024px;
    }
  }
}

#Page.subpage {
  .webcam-frame {
    position: relative;
    margin-bottom: 20px;
    text-align: center;

    &:before {
      position: absolute;
      top: 1%;
      left: 1%;
      display: block;
      width: 98%;
      height: 100%;
      background-image: url('/themes/LRS/assets/dist/images/overlay.png');
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      content: '';
    }
  }
}

#Page.subpage.webcam {
  padding-bottom: 0;

  .footer-nav {
    display: none;
  }

  .webcam .slide {
    width: 100%;
    height: auto;
  }

  .webcam-title {
    position: relative;
    clear: left;

    @media only screen and (min-width: 1200px) {
      left: 25px;
      clear: none;
      width: calc(100% - 25px);
    }

    h1 {
      padding: 10px 0 0 0;
      color: #17468f;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 16px;
      line-height: 16px;

      @media only screen and (min-width: 1200px) {
        font-size: 30px;
        line-height: 30px;
      }
    }
  }

  .webcam-controls {
    display: inline-block;
    width: 100%;
    color: #606060;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 14px;
    font-family: $primary-font;

    .back {
      display: inline-block;
      float: left;
      padding: 0 5px;

      &:hover {
        background: #d1da26;
        color: #000000;
      }

      &:before {
        padding-right: 10px;
        content: '\f053';
        font-family: FontAwesome;
      }
    }

    .current {
      display: inline-block;
      float: none;
      padding: 0 5px;

      &:hover {
        background: #d1da26;
        color: #000000;
      }
    }

    .next {
      display: inline-block;
      float: right;
      padding: 0 5px;

      &:hover {
        background: #d1da26;
        color: #000000;
      }

      &:after {
        padding-left: 10px;
        content: '\f054';
        font-family: FontAwesome;
      }
    }
  }

  .webcam-wrapper {
    background: white;
  }

  .webcam-content {
    position: relative;
    margin: 0 auto;
    max-width: 960px;
    width: 100%;

    @media only screen and (min-width: 960px) {
      padding: 0;
      height: calc(70% - 97px);
    }

    .subpage-content-full {
      background: #ffffff;
    }

    .webcam-container {
      position: relative;
      bottom: -30px;
      margin-bottom: 10px;

      img.cycle-slide + .title {
        display: none;
      }

      img.cycle-slide-active + .title {
        position: relative;
        top: 10px;
        display: block;

        @media only screen and (min-width: 960px) {
          top: 5px;
        }
      }

      .slide {
        width: 100%;
        height: auto;
        color: #606060;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 14px;
        line-height: 14px;

        // &:after {
        //   position: absolute;
        //   right: 5px;
        //   bottom: 35px;
        //   width: 43px;
        //   height: 50px;
        //   background-image: url('/themes/LRS/assets/dist/images/build-grow-repeat.png');
        //   background-position: center center;
        //   background-size: 43px 50px;
        //   background-repeat: no-repeat;
        //   content: '';

        //   @media only screen and (min-width: 1200px) {
        //     width: 110px;
        //     height: 129px;
        //     background-size: 110px 129px;
        //   }
        // }
      }

      .popup {
        text-align: center;

        &:after {
          position: absolute;
          right: 5px;
          bottom: 35px;
          z-index: 999;
          width: 43px;
          height: 50px;
          background-image: url('/themes/LRS/assets/dist/images/build-grow-repeat.png');
          background-position: center center;
          background-size: 43px 50px;
          background-repeat: no-repeat;
          content: '';

// opacity: 0.3;
          opacity: 0.05;

          @media only screen and (min-width: 1200px) {
            width: 110px;
            height: 129px;
            background-size: 110px 129px;
          }
        }
      }

      // .title {
      //   margin-top: 14px;

      //   @media only screen and (min-width: 960px) {
      //     margin-top: 8px;
      //     margin-bottom: 8px;
      //   }
      // }
    }

    .controls {
      // position: absolute;
      position: relative;

// bottom: 7px;
      bottom: 0;
      z-index: 101;
      overflow: hidden;
      padding: 10px 0;
      width: calc(100% - 20px);
      height: auto;
      color: #606060;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 14px;

      @media only screen and (min-width: 960px) {
        // bottom: 4px;
        bottom: 0;
        padding: 5px 0;
        width: 100%;
      }

      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background: #000000;
        content: '';
      }

      .prev,
      .next {
        position: relative;
        letter-spacing: 2px;
        cursor: pointer;

        &:hover,
        &:focus {
          background: #d1da26;
          color: black;
        }

        &:after {
          position: absolute;
          top: 4px;
          text-decoration: inherit;
          font-weight: normal;
          font-style: normal;
          font-size: 14px;
          font-family: FontAwesome;
        }
      }

      .prev {
        float: left;
        padding: 4px 6px 4px 22px;

        &:after {
          left: 4px;
          content: '\f053';
        }
      }

      .next {
        float: right;
        padding: 4px 22px 4px 6px;

        &:after {
          right: 4px;
          content: '\f054';
        }
      }
    }

    #adv-custom-pager {
      position: relative;
      display: flex;
      overflow-x: hidden;
      overflow-y: scroll;
      margin: 0 auto;
      margin: 0 auto;
      padding: 33px 0;
      max-width: 960px;
      height: calc(30vh - 37px);

      flex-wrap: wrap;

      a,
      a.gallery-image {
        padding: 0 18px 18px;
        color: #606060;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 14px;

        @media only screen and (min-width: 640px) {
          display: inline-block;
          padding: 0 10px 10px 10px;
          width: 50%;
        }

        @media only screen and (min-width: 960px) {
          padding: 0 18px 18px 18px;
          width: 33.333%;
        }

        .title {
          text-align: center;
        }

        &:hover,
        &.cycle-pager-active {
          text-decoration: none;

           > img {
            outline: #d1da26 solid 5px;
            outline-offset: -5px;
          }
        }

        &:focus img {
          outline: #d1da26 solid 5px;
        }
      }
    }
  }

  .webcam-sidebar {
    position: relative;
    margin: 0 auto;
    max-width: 960px;
    width: 100%;

    @media only screen and (min-width: 960px) {
      margin: 0;
      max-width: 100%;
    }

    &:before {
      position: absolute;
      top: -1px;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background: black;
      content: '';
    }

     > div {
      overflow-x: hidden;
      overflow-y: scroll;

      @media only screen and (min-width: 960px) {
        margin: 0 auto;
        max-width: 960px;
        height: calc(30vh - 37px);
      }
    }

    .webcam-gallery {
      display: flex;
      padding: 10px;

      flex-wrap: wrap;

      @media only screen and (min-width: 640px) {
        margin: 0 -10px;
      }

      @media only screen and (min-width: 960px) {
        margin: 0 -18px;
        padding: 33px 0;
      }

      // .gallery-image {
      //   padding-bottom: 10px;
      //   color: #606060;
      //   text-align: center;
      //   text-transform: uppercase;
      //   letter-spacing: 2px;
      //   font-size: 14px;
      //   line-height: 14px;

      //   &:last-child {
      //     padding-bottom: 0;
      //   }

      //   @media only screen and (min-width: 640px) {
      //     display: inline-block;
      //     padding: 0 10px 10px 10px;
      //     width: 50%;
      //   }

      //   @media only screen and (min-width: 960px) {
      //     padding: 0 18px 18px 18px;
      //     width: 33.333%;
      //   }

      //   .title {
      //     margin-top: 14px;

      //     @media only screen and (min-width: 640px) {
      //       margin-top: 5px;
      //     }
      //   }

      //   &:hover > img,
      //   &:focus > img,
      //   &.cycle-pager-active > img {
      //     outline: 5px solid #d1da26;
      //     outline-offset: -5px;
      //   }
      // }
    }
  }

  .page-footer {
    position: relative;
    bottom: auto;
  }
}
