* {
  box-sizing: border-box;

  font-kerning: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  position: relative;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  height: 100%;
  font-size: 100%;
  font-family: sans-serif;
}

// body.homepage {
//   background: url(/themes/LRS/assets/dist/images/lrs-building-5.jpg) no-repeat fixed;
//   background-size: cover;
// }

.clearfix:after {
  display: table;
  clear: both;
  content: '';
}

a {
  color: blue;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:visited {
    color: blue;
  }

  &:hover,
  &:focus {
    color: purple;
    text-decoration: underline;
  }
}

input {
  appearance: none;
}

.element-invisible {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  width: 1px;
  height: 1px;
}

.clear {
  zoom: 1;

  &:before,
  &:after {
    display: table;
    content: '';
  }

  &:after {
    clear: both;
  }
}
