#clickdimensionsForm {
  margin: auto !important;
  max-width: 100% !important;

  @media (max-width:768px) {
    max-width: 100% !important;
  }

  tr:nth-child(odd) {
    background-color: transparent !important;
  }
}

.responsiveRow {
  display: flex;
  // min-height: 50px;
}

.emptyCell {
  display: none;
}

.responsiveCell {
  text-align: left !important;
  
  div.alignBottom,
  div.alignTop {
    display: inline-block !important;
  }
}

.responsiveCellSize1 {
  width: 50%;
  min-width: 0;
  flex: 1;
  margin: 0 10px;

  span {
    font-family: inherit !important;
  }
}

.responsiveCellSize2 {
  width: 100%;
  min-width: 0;
  margin:  0 10px;
}

.clickdform {
  font-family: inherit;

  input[type='text'],
  input[type='password'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='week'],
  input[type='email'],
  input[type='number'],
  input[type='search'],
  input[type='tel'],
  input[type='time'],
  input[type='url'],
  input[type='radio'],
  textarea,
  select {
    border: 1px solid #d6d6d6 !important;
    color: #0a0a0a !important;
    padding: 5px 10px 6px !important;
    border-radius: 0 !important;
    background: #fff !important;
    margin: 0 0 1rem !important;
    font-family: inherit !important;
    height: 35px !important;

    &:active,
    &:focus {
      border: 1px solid rgba(2,139,255,1) !important;
      outline: 0;
      background: #fff !important;
    }

    &[disabled] {
      border-color: palette(gray, 6);
      background: palette(gray, 6);
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    border: none !important;
    box-shadow: none !important;
    background: #fff !important;
    margin: 10px 10px 10px 0 !important;
    transition: none !important;
    height: auto !important;
  }

  .buttonContainer {
    width: 100% !important;
  }

  #btnSubmit {
    position: relative !important;
    background: transparent !important;
    width: 100% !important;
    padding: 13px 0 16px !important;
    margin: 20px 0 !important;
    color: #17468f !important;
    font-size: 22px !important;
    font-weight: 600 !important;
    cursor: pointer !important;
    display: block !important;
    font-family: inherit !important;
    text-align: left !important;
    border-color: #d7d7d7 !important;
    border-style: solid !important;
    border-width: 4px 0 !important;

    &:active,
    &:hover {
      border-color: #17468f !important;      
    }
  }

  .minSize1,
  .minSize2,
  .maxSize1,
  .maxSize2 {
    width:100% !important;
    min-width:100%  !important;
    font-size: 20px !important;
    font-family: inherit !important;
    margin: 0 0 2px 0 !important;

    b,
    strong,
    span {
      font-weight: 800 !important;
      font-family: inherit !important;
      color: #4d4d4d !important;
      margin: 10px 0 !important;
      display: inline-block !important;
      line-height: 1.2 !important;
      font-size: 18px !important;
    }

    textarea {
      height: 35px !important;

      &:focus {
        height: 160px !important;
      }
    }

  }

}

.QapTcha {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
