$subpage-content-background-color: $gallery;
$subpage-content-color: $darker-scorpion;
$subpage-content-color-larger: $scorpion;
$subpage-content-h1-color: $lrs-primary;
$subpage-content-h2-color: $tundora;
$subpage-content-h3-color: $darker-dove-gray;
$subpage-content-h4-color: $darker-dove-gray;
$subpage-content-p-color: $dove-gray;
$subpage-content-p-big-color: $black;
$subpage-content-a-color: $lrs-primary;
$subpage-content-ul-color: $dove-gray;
$subpage-content-li-before-background-color: $lrs-secondary;
$subpage-content-ol-color: $dove-gray;
$subpage-content-blockquote-border-color: $darker-alto;
$subpage-content-blockqoute-p-color: $gray;
$subpage-featured-content-image-caption-border-color: $darkest-alto;
$subpage-featured-content-image-caption-color: $darker-scorpion;
$copy-heavy-subpage-content-p-color: $scorpion;
$copy-heavy-subpage-content-p-big-color: $black;
$copy-heavy-subpage-content-ul-color: $scorpion;
$subpage-content-primary-column-background-color: $gallery;
$subpage-content-secondary-column-background-color: $brutus;

.subpage-content,
.subpage-content-full {
  position: relative;
  background: $subpage-content-background-color;
  color: $subpage-content-color;
  font-family: $primary-font;

@include respond-to(960px) {
    color: $subpage-content-color-larger;
  }

  h1 {
    clear: both;
    margin-bottom: 1rem;
    color: $subpage-content-h1-color;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;
  }

  h2 {
    clear: both;
    color: $subpage-content-h2-color;
    font-weight: 900;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;

    & + h3 {
      margin-top: 1rem;
    }
  }

  h3 {
    clear: both;
    color: $subpage-content-h3-color;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;

    & + h4 {
      margin-top: 1rem;
    }
  }

  h4 {
    clear: both;
    color: $subpage-content-h4-color;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    line-height: 21px;
  }

  p {
    margin: 0 0 18px 0;
    margin-bottom: 21px;
    // color: $subpage-content-p-color;
    font-size: 16px;
    line-height: 21px;

    &.big {
      color: $subpage-content-p-big-color;
      font-size: 22px;
      line-height: 28px;
    }

    &.small {
      font-style: italic;
      font-size: 13px;
      line-height: 18px;
    }
  }

  a {
    color: $subpage-content-a-color;
    font-weight: 600;
  }

  // ul:not(.checkbox-list):not(.filter-list):not(.redactor-toolbar):not(.post-categories):not(.dnp-pager):not(.tab-list) {
  //   display: block;
  //   overflow: hidden;
  //   margin: 18px 0;
  //   padding: 0;
  //   color: $subpage-content-ul-color;

  //   li {
  //     position: relative;
  //     float: left;
  //     margin: 0;
  //     padding: 0 0 0 35px;
  //     width: 100%;
  //     list-style: none;

  //     &:before {
  //       position: absolute;
  //       top: 8px;
  //       left: 15px;
  //       display: block;
  //       width: 4px;
  //       height: 4px;
  //       border-radius: 50%;
  //       background-color: $subpage-content-li-before-background-color;
  //       content: '';
  //     }
  //   }
  // }

  // ol:not(.checkbox-list):not(.filter-list):not(.redactor-toolbar):not(.post-categories):not(.aup) {
  //   float: left;
  //   margin: 18px 0;
  //   padding: 0;
  //   width: 100%;
  //   color: $subpage-content-ol-color;

  //   li {
  //     position: relative;
  //     display: block;
  //     float: left;
  //     margin: 0 0 0 20px;
  //     padding: 0 0 0 15px;
  //   }
  // }

  ul,
  ol {
    @include fontsize(20 28);
    font-weight: 400;
    margin-left: 0;
    margin: 15px 0 25px;

    &.no-bullets {
      list-style: none;
      padding-left: 0;
      text-indent: 0;
      margin: 0 0 20px;
    }
  }

  ul:not([class]) {
    padding-left: 30px;
    list-style: none;
    text-indent: -2px;

    > li {
      margin-bottom: 10px;

      &:before {
        position: relative;
        display: inline-block;
        font-family: 'Font Awesome 5 Pro', 'Font Awesome 5 Free', FontAwesome;
        font-size: 4px;
        line-height: 4px;
        font-weight: 900;
        top: -5px;
        left: -8px;
        color: #0099cc;
        content: '\f111';
      }

      ul {
        margin-bottom: 0;
        font-size: 16px;
        padding-left: 20px;
        text-indent: -2px;

        li {
          &:before {
            font-size: 12px;
          }
        }
      }
    }
  }

  ol:not([class]) {
    list-style: none;
    counter-reset: numbers;
    padding-left: 30px;
    text-indent: -6px;

    > li {
      counter-increment: numbers;
      margin-bottom: 10px;

      &:before {
        position: relative;
        display: inline-block;
        left: -8px;
        content: counter(numbers);
      }

      >ol,
      >ul {
        margin-bottom: 0;
        font-size: 18px;
        padding-left: 30px;
        text-indent: -2px;

        li {
          margin-bottom: 10px;

          &:before {
            content: '\f111';
            font-family: 'Font Awesome 5 Pro', 'Font Awesome 5 Free', FontAwesome;
            font-weight: 900;
            display: inline-block;
            position: relative;
            font-size: 4px;
            line-height: 4px;
            top: -5px;
            left: -8px;
            color: #0099cc;
          }
        }
      }
    }
  }

  ol.aup {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 30px;

    li {
      margin-bottom: 10px;
    }
  }

  blockquote {
    position: relative;
    clear: both;
    margin: 60px 0;
    padding: 14px 0;
    border-top: solid 5px $subpage-content-blockquote-border-color;
    border-bottom: solid 5px $subpage-content-blockquote-border-color;

    &:before {
      position: absolute;
      top: -30px;
      left: 50%;
      margin-left: -25px;
      content: url('/themes/LRS/assets/dist/images/blockquote-before.png');
    }

    &:after {
      position: absolute;
      bottom: -36px;
      left: 50%;
      margin-left: -25px;
      content: url('/themes/LRS/assets/dist/images/blockquote-after.png');
    }

    &.pull-left {
      @include respond-to(960px) {
        float: left;
        width: 40%;
      }
    }

    &.pull-right {
      @include respond-to(960px) {
        float: right;
        width: 40%;
      }
    }

    p {
      margin: 0;
      color: $subpage-content-blockqoute-p-color;
      font-weight: 400;
      font-style: italic;
      font-size: 18px;
      line-height: 28px;
    }

    cite {
      font-size: 16px;
      color: $lrs-primary;
    }
  }

  .featured-content-image {
    display: block;
    float: left;
    margin: 0 20px 20px 0;
    width: 250px;

    img {
      display: block;
      margin: 0;
    }

    .featured-content-image-caption {
      display: block;
      padding: 8px 0;
      width: 100%;
      border-width: 0 3px 3px;
      border-style: solid;
      border-color: $subpage-featured-content-image-caption-border-color;
      background: $steel;
      color: $subpage-featured-content-image-caption-color;
      text-align: center;
      font-style: italic;
      font-size: 14px;
    }
  }

  .gdpr-content {

    h2::before {
      content: "";
      display: block;
      height: 60px;
      margin: -60px 0 0;
    }

    h3 {
      text-decoration: underline;
      margin-bottom: 10px;
    }

    table,
    tbody,
    tr,
    td,
    th {
      border: 1px solid #606060;
    }
  }
}

.copy-heavy .subpage-content,
.copy-heavy .subpage-content-full {
  .primary-content {

    h1.subscribe {
      position: relative;
      color: $pantone-286c;
      font-size: 22px;
      line-height: 28px;
      border-bottom: 2px solid #cfcfcf;
      padding-bottom: 20px;
      padding-left: 0;
      margin-bottom: 40px;
      text-transform: none;
      font-weight: 600;

      @include respond-to(960px) {
        font-size: 36px;
        line-height: 30px;
        padding-left: 142px;
        padding-bottom: 60px;

        &::before {
          position: absolute;
          left: 0;
          top: -33px;
          width: 120px;
          height: 86px;
          background-image: url(/themes/LRS/assets/dist/images/icon-heading-envelope.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          content: '';
        }

      }
    }

    @include respond-to(960px) {
      h1 {
        margin-bottom: 30px;
      }

      h2 {
        font-size: 22px;
        line-height: 28px;

        // &.subscribe {
        //   position: relative;
        //   color: $pantone-286c;
        //   font-size: 36px;
        //   line-height: 30px;
        //   border-bottom: 2px solid #cfcfcf;
        //   padding-bottom: 60px;
        //   padding-left: 142px;
        //   margin-bottom: 40px;

        //   &::before {
        //     position: absolute;
        //     left: 0;
        //     top: -33px;
        //     width: 120px;
        //     height: 86px;
        //     background-image: url(/themes/LRS/assets/dist/images/icon-heading-envelope.svg);
        //     background-repeat: no-repeat;
        //     background-position: center;
        //     background-size: cover;
        //     content: '';
        //   }
        // }
      }

      p {
        margin-bottom: 28px;
        color: $copy-heavy-subpage-content-p-color;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;

        &.big {
          color: $copy-heavy-subpage-content-p-big-color;
          font-size: 28px;
          line-height: 36px;
        }

        &.small {
          font-style: italic;
          font-size: 13px;
          line-height: 18px;
        }
      }

      // ul:not(.checkbox-list):not(.filter-list):not(.redactor-toolbar):not(.post-categories):not(.tab-list),
      // ol:not(.checkbox-list):not(.filter-list):not(.redactor-toolbar):not(.post-categories):not(.aup) {
      //   overflow: hidden;
      //   margin-top: 28px;
      //   margin-bottom: 28px;
      //   color: $copy-heavy-subpage-content-ul-color;
      //   font-weight: 400;
      //   font-size: 21px;
      //   line-height: 28px;
      // }

      // ol:not(.checkbox-list):not(.filter-list):not(.redactor-toolbar):not(.post-categories):not(.aup) {
      //   li {
      //     display: block;
      //     float: left;
      //     margin: 0 0 0 30px;
      //     padding: 0 0 0 5px;
      //     width: 100%;
      //   }
      // }

      // ul:not(.checkbox-list):not(.filter-list):not(.redactor-toolbar):not(.post-categories):not(.tab-list) {
      //   li {
      //     display: block;
      //     float: left;
      //     width: 100%;

      //     &:before {
      //       top: 12px;
      //     }
      //   }
      // }

      blockquote {
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.subpage-content {
  .primary-content {
    padding: 45px 30px 60px 30px;
    font-size: 16px;
    line-height: 21px;

    @include respond-to(1070px) {
      padding-bottom: 97px;
    }

    @include respond-to(960px) {
      padding: 75px 31% 75px 14%;

      @at-root .subpage.subscription-center .subpage-content .primary-content {
        padding: 110px 14%;
      }

      @at-root .subpage.subscription-center-test .subpage-content .primary-content {
        padding: 110px 14%;
      }
    }

    .content-pane {
      max-width: 960px;
    }
  }
}

.subpage-content-full {
  .primary-content-full {
    font-size: 16px;
    line-height: 21px;
  }
}

.sp-columns {
  .sp-primary-column {
    background: $subpage-content-primary-column-background-color;

@include respond-to(960px) {
      float: left;
      width: 75%;
    }
  }

  .sp-secondary-column {
    background: $subpage-content-secondary-column-background-color;

@include respond-to(960px) {
      float: left;
      width: 25%;
    }
  }
}

a.pop-out {
  position: absolute;
  right: 0;
  margin-top: -20px;
  padding-right: 5px;
  width: 100%;
  height: 20px;
  background: #000000;
  color: #ffffff;
  text-align: right;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 20px;

  &:after {
    padding-left: 5px;
    color: #d1da26;
    content: '\f14c';
    font-family: FontAwesome;
  }
}

.construction-logo-container {
  width: 100%;

  @media only screen and (max-width: 570px) {
    text-align: center;
  }

  .logo-one {
    display: inline-block;
    padding: 10px 20px;
    border-right: 2px solid #8d9191;

    @media only screen and (max-width: 570px) {
      display: block;
      border: none;
    }

    img {
      display: inline-block;
    }
  }

  .logo-two {
    position: relative;
    top: 40px;
    display: inline-block;
    padding: 0 15px;

    @media only screen and (max-width: 570px) {
      top: 20px;
    }

    img {
      display: block;

      &:last-of-type {
        padding-top: 5px;
      }
    }
  }
}
