$nav-primary-base-color: $lrs-primary;
$nav-primary-li1-border-color: darken(saturate(adjust-hue($nav-primary-base-color, 0.0859), 6.8702), 7.0588);
$nav-primary-plus-border-color: darken(saturate(adjust-hue($nav-primary-base-color, 0.0859), 6.8702), 7.0588);
$nav-primary-plus-background-color: $nav-primary-base-color;
$nav-primary-plus-horiz-background-color: $white;
$nav-primary-plus-vert-background-color: $white;
$nav-primary-open-plus-background-color: darken(saturate(adjust-hue($nav-primary-base-color, 0.0859), 6.8702), 7.0588);
$nav-primary-a1-background-color: $nav-primary-base-color;
$nav-primary-a1-color: $white;
$nav-primary-a1-hover-color: $nav-primary-base-color;
$nav-primary-a1-color-big: $mine-shaft;
$nav-primary-ul2-background-color: $nav-primary-base-color;
$nav-primary-ul2-border-color: $white;
$nav-primary-a2-color: $white;

body.has-fixed-menu {
  padding-top: 50px;
}

.nav-primary {
  position: absolute;
  top: 75px;
  left: 0;
  z-index: 99;
  display: none;
  width: 100%;
  background: $nav-primary-base-color;

@include respond-to(1350px) {
    position: static;
    left: auto;
    display: block;
    float: left;
    width: auto;
    background: transparent;
    box-shadow: none;
  }

  &.active {
    display: block;
  }

  a {
    text-decoration: none;
  }

  .ul1 {
    position: relative;
    z-index: -1;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;

@include respond-to(1350px) {
      position: static;
      margin-left: 13px;
    }
  }

  .li1 {
    position: relative;
    border-bottom: 1px solid $nav-primary-li1-border-color;

@include respond-to(1350px) {
      float: left;
      margin-bottom: -21px;
      padding-right: 0;
      border-bottom: none;
    }

    &.parent,
    &.has_children {
      padding-right: 60px;

@include respond-to(1350px) {
        padding-right: 0;
      }
    }

    &.first {
      .a1 {
        box-shadow: inset 1px 4px 9px -6px rgba($black, 0.95);

@include respond-to(1350px) {
          box-shadow: none;
        }
      }

      .plus {
        box-shadow: inset -1px 4px 9px -6px rgba($black, 0.95);
      }
    }

    &:hover,
    &:focus {
      .a1 {
        @include respond-to(1350px) {
          color: $nav-primary-a1-hover-color;
        }
      }

      .ul2 {
        @include respond-to(1350px) {
          display: block !important;
        }
      }
    }

    .plus {
      position: absolute;
      top: 0;
      right: 0;
      display: none;
      width: 60px;
      height: 100%;
      border-left: 1px solid $nav-primary-plus-border-color;
      background: $nav-primary-plus-background-color;
      cursor: pointer;

@include respond-to(1350px) {
        display: none;
      }  -webkit-tap-hightlight-color: none;
      user-select: none;

      .horiz {
        position: relative;
        display: inline-block;
        margin: 30px 0 0 22px;
        padding: 0;
        width: 14px;
        height: 2px;
        background: $nav-primary-plus-horiz-background-color;
      }

      .vert {
        position: relative;
        top: 0;

// left: -21px;
        left: -18px;
        display: inline-block;
        margin: 0 0 0 4px;
        padding: 0;
        width: 14px;
        height: 2px;
        background: $nav-primary-plus-vert-background-color;
        transform: rotate(90deg);
      }
    }

    &.open {
      .plus {
        background-color: $nav-primary-open-plus-background-color;

        .horiz {
          position: absolute;
          top: calc(50% - 1px);
          margin-top: 0;
        }

        .vert {
          display: none;
        }
      }

      .ul2 {
        display: block;

@include respond-to(1350px) {
          display: none;
        }
      }
    }

    &.parent,
    &.has_children {
      .plus {
        display: block;

@include respond-to(1350px) {
          display: none;
        }
      }
    }
  }

  .a1 {
    display: block;
    padding: 18px 14px 17px;
    background: $nav-primary-a1-background-color;
    color: $nav-primary-a1-color;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px;

@include respond-to(1350px) {
      position: relative;
      padding: 18px 11px 38px;
      background: none;
      color: $nav-primary-a1-color-big;
    }
  }

  .ul2 {
    display: none;
    margin: -12px 0 0 0;
    padding: 0 24px 17px 24px;
    background: $nav-primary-ul2-background-color;
    list-style: none;

@include respond-to(1350px) {
      position: absolute;
      left: 50%;
      z-index: 999;
      display: none;
      margin: 0 0 0 -122px;
      padding: 38px 24px 40px;
      width: 244px;
      height: auto;
      border-right: 1px solid $nav-primary-ul2-border-color;
      border-bottom: 1px solid $nav-primary-ul2-border-color;
      border-left: 1px solid $nav-primary-ul2-border-color;
      background-color: $nav-primary-ul2-background-color;
      background-image: url('/themes/LRS/assets/dist/images/drop-triangle.png'), url('/themes/LRS/assets/dist/images/flyout-shadow.png');
      background-position: 50% 0, 0 0;
      background-repeat: no-repeat, repeat-x;
      list-style: none;
    }
  }

  .li2 {
    @include respond-to(1350px) {
      display: block;
    }

    &:first-child {
      .a2 {
        @include respond-to(1350px) {
          padding-left: 0;
        }
      }
    }

    &:last-child {
      .a2 {
        @include respond-to(1350px) {
          padding-right: 0;
        }
      }
    }
  }

  .a2 {
    display: block;
    padding: 6px 0;
    color: $nav-primary-a2-color;
    font-weight: 700;
    font-size: 15px;
    font-family: $primary-font;

@include respond-to(1350px) {
      display: block;
      margin: 0;
      padding: 3px 0 4px;
      text-align: center;
    }
  }

  .fixed & {
    .li1:hover,
    .li1:focus {
      .ul2 {
        display: none;
      }
    }

    .a1 {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
}
