$touch-nav-toggle-color: $lightened-gray;
$touch-nav-toggle-bar-background-color: $lightened-gray;
$touch-nav-toggle-open-bar-background-color: $lrs-primary;

.touch-nav-toggle {
  display: block;
  float: left;
  max-width: 29px;
  margin-top: 12px;
  margin-left: 14px;
  color: $touch-nav-toggle-color;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba($black,0);
  -webkit-tap-highlight-color: transparent; /* For some Androids */

  @include respond-to(1350px) {
    display: none;
  }

  .bar {
    width: 29px;
    height: 3px;
    margin-top: 0;
    margin-bottom: 4px;
    padding: 0;
    background: $touch-nav-toggle-bar-background-color;
    font-size: 1px !important;
    line-height: 1px !important;
  }

  span {
    display: block;
    max-width: 29px;
    padding: 0;
    font-weight: 700;
    font-size: 1px;
    text-indent: -1px;
  }

  &.open {
    margin-bottom: -3px;

    .bar {
      background: $touch-nav-toggle-open-bar-background-color;

      &.middle {
        display: none;
      }

      &.top {
        margin-top: 7px !important;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &.bottom {
        margin-top: -7px !important;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    .text {
      width: 0;
      height: 0;
      margin-top: 1rem;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-top: 14px solid $darker-alto;
      overflow: hidden;
    }
  }
}
