$body-background-color: $gallery;

html,
body {
  position: relative;
  min-width: 320px;
  width: 100%;
}

body.homepage {
  background: url('/themes/LRS/assets/dist/images/img-lrs-40th-anniversary-background-3.jpg') no-repeat 0 0;
  background-attachment: fixed;
  background-size: cover;
}

body {
  background-color: $body-background-color;
  font-family: $primary-font;
}

form {
  margin: 0;
}

.page {
  min-width: 320px;
  min-height: 100%;
  height: 100%;
}

#Page {
  @include respond-to(640px) {
    padding-bottom: 74px;
  }
  @include respond-to(1070px) {
    padding-bottom: 37px;
  }
}