h1,
h2,
h3,
h4,
h5,
h6 {
}

.txt-underline {
  text-decoration: underline;
}

hr {
  border: 0;
  height: 0;
  border-top: 3px solid #d7d7d7;
  margin: 45px 0;
}