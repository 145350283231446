$location-info-background-color: $white;
$location-content-background-color: $christmas-snow;
$location-content-inner-p: $medium-scorpion;
$location-locale-color: $tundora;
$location-image-border-color: $mercury;
$location-a-color: $lrs-primary;
$location-return-link-border-color: $darkest-alto;
$location-selector-border-color: $darkdark-alto;
$location-selector-background-color: $white;

.location-detail {
  position: relative;
  padding: 50px 25px;
  width: 100%;

@include respond-to(960px) {
    float: right;
    padding: 50px;
    max-width: 1200px;
  }

@include respond-to(1300px) {
    padding-right: 125px;
  }

  .location-info {
    display: block;
    margin: 40px 0;
    background: $location-info-background-color;
    box-shadow: 0 0 5px rgba($black, 0.2);
  }

  .location-map {
    display: block;

@include respond-to(960px) {
      float: left;
      width: calc((100% / 3) * 2);
    }

    .google-map {
      min-height: 450px;
      height: 100%;
    }
  }

  .location-content {
    position: relative;
    display: block;
    padding: 30px;
    background: $location-content-background-color;
@include respond-to(960px) {
      float: left;
      width: calc((100% / 3) * 1);
    }

    .location-content-inner {
      position: relative;
      display: block;

@include respond-to(960px) {
        float: none;
        width: 100%;
      }

      p {
        color: $location-content-inner-p;
      }
    }

    .location-locale {
      margin-bottom: 1em;
      color: $location-locale-color;
      font-size: 22px;
    }

    .location-image {
      position: relative;
      padding: 30px 0;
      width: 100%;
      border-top: 1px solid $location-image-border-color;
@include respond-to(960px) {
        margin-top: 60px;
      }

      img {
        position: relative;
        display: block;
        margin: 0;
        max-height: 150px;
        width: auto;
      }
    }

    a {
      color: $location-a-color;
      font-weight: 500;
    }
  }

  .location-description {
    padding: 14px 0;

    h4,
    p {
      font-size: 16px;
      line-height: 24px;

@include respond-to(960px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  .location-return-link {
    display: block;
    padding: 8px 0;
    border-top: 3px solid $location-return-link-border-color;

    a {
      display: block;
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.location-selector {
  padding: 50px 25px;

  h2 {
    margin-bottom: 40px;
    text-transform: uppercase;
  }

  h3 {
    margin-bottom: 4px;
    padding-left: 35px;
    height: 30px;
    font-size: 20px;
    line-height: 30px;
  }

  select {
    display: block;
    padding: 4px 8px;
    border: 1px solid $location-selector-border-color;
    background: $location-selector-background-color;
  }

  .lrs-office-selector {
    margin-bottom: 20px;

    h3 {
      background: url('/themes/lrs/assets/dist/images/office.png') no-repeat left;
    }
  }

  .lrs-distributor-selector {
    h3 {
      background: url('/themes/lrs/assets/dist/images/distributor.png') no-repeat left;
    }
  }
}
