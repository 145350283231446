$nav-departments-background-color: $darker-scorpion;
$nav-departments-border-color: $white;
$nav-departments-a-background-color: $dove-gray;
$nav-departments-second-row-a-border-color: $white;
$nav-departments-li-border-color: $white;
$nav-departments-a-hover-background-color: $gray;
$nav-departments-expand-color: $white-rice;
$nav-departments-expand-span-background-color: $nobel;

.nav-departments {
  position: relative;
  width: 100%;
  border-bottom: 1px solid $nav-departments-border-color;
  background-color: $nav-departments-background-color;

// background-image: url('/themes/LRS/assets/dist/images/departments-shadow.png');
  background-position: top left;
  background-repeat: repeat-x;

@include respond-to(1275px) {
    padding-right: 77px;
  }

  ul {
    display: none;
    margin: 0;
    padding: 0;
    list-style: none;

@include respond-to(1275px) {
      display: block;
    }

    &.show-mobile {
      display: block;

@include respond-to(1275px) {
        display: none;
      }
    }

    &.show-desktop {
      @include respond-to(1275px) {
        display: block;
      }
    }

    &.first_row {
      @include respond-to(1275px) {
        @include block-grid(5, 0, false);
      }

      a {
        @include respond-to(1275px) {
          background-color: $nav-departments-a-background-color;

// background-image: url('/themes/LRS/assets/dist/images/departments-shadow.png');
          background-position: top left;
          background-repeat: repeat-x;
        }
      }
    }

    &.second_row {
      background-image: none;

@include respond-to(1275px) {
        display: none;
      }

      &.show-desktop {
        @include respond-to(1275px) {
          display: block;
          border-top: 1px solid $nav-departments-second-row-a-border-color;

@include block-grid(4, 0, false);
        }
      }
    }

    li {
      display: block;
      margin: 0;
      padding: 0;
      border-top: solid 1px $nav-departments-li-border-color;

@include respond-to(1275px) {
        border-top: none;
        border-right: solid 1px $nav-departments-li-border-color;
      }
    }

    a {
      display: block;
      margin: 0;
      padding: 16px;
      background-color: $nav-departments-a-background-color;
      color: $white;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      text-shadow: 0 0 4px rgba($black, 0.5);
      letter-spacing: 0.5px;
      font-weight: 400;
      font-size: 14px;
      font-family: $primary-font;
      line-height: 20px;
      transition: background-color 0.2s ease-out;

      &:hover {
        background-color: $nav-departments-a-hover-background-color;
        ;
      }
    }
  }

  .expand {
    position: relative;
    margin: 0;
    padding: 10px 0 11px;
    width: 100%;
    height: 100%;
    color: $nav-departments-expand-color;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    font-family: $primary-font;

    -webkit-tap-hightlight-color: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
         -o-user-select: none;
            user-select: none;

    span {
      position: relative;
      display: inline-block;
      margin: 0 0 0 4px;
      padding: 0;
      width: 14px;
      height: 2px;
      background: $nav-departments-expand-span-background-color;
    }

    span.horiz {
      top: -4px;

@include respond-to(1275px) {
        top: 0;
        left: 9px;
      }
    }

    &.open .vert {
      -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
              transform: rotate(0deg);
    }

    span.vert {
      top: -4px;
      left: -21px;
      -webkit-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
              transform: rotate(90deg);

@include respond-to(1275px) {
        top: 0;
        left: -12px;
      }
    }
  }

  .mobile-departments-nav-toggle {
    position: relative;
    display: block;
    margin: 0;
    padding: 10px 0 11px;
    width: 100%;
    height: 100%;
    color: $nav-departments-expand-color;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    font-family: $primary-font;

@include respond-to(1275px) {
      display: none;
    }  -webkit-tap-hightlight-color: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
         -o-user-select: none;
            user-select: none;

    &::before {
      display: inline-block;
      content: 'View LRS Divisions';
    }

    &.open {
      &::before {
        content: 'Hide LRS Divisions';
      }
    }
  }

  .desktop-departments-nav-toggle {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    padding-top: 4px;
    width: 77px;

    @include respond-to(1275px) {
      display: block;
    }

    &:hover {
      text-decoration: none;
    }

    &::after {
      position: relative;
      display: none;
      margin-top: 3px;
      content: 'More';

@include respond-to(1275px) {
        display: block;
      }
    }

    &.open {
      padding-top: 29px;

      &::after {
        content: 'Less';
      }
    }
  }
}
