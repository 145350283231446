.alerts {
  background: #001645 !important;
  z-index: 20;

  .alert {
    position: relative;
    text-decoration: none;
    text-align: left !important;
    padding: 15px 30px 15px 60px !important;
    overflow: hidden;

    &:hover,
    &:focus {
      outline: none;
      text-decoration: underline;

      .alert-btn {
        background: white;
        color: #001645;
      }
    }

    @media only screen and (min-width: 768px) {
      padding: 35px 280px 35px 60px !important;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 50px;
      height: 100%;
      background-color: #001645;
    }

    .icon {
      position: absolute;
      display: inline-flex;
      align-items: center;
      left: 15px;
      font-size: 22px !important;
      color: #09c;
      font-weight: 900;
      text-decoration: none;
      text-transform: uppercase;

      .alert-icon {
        position: relative;
        display: inline-block;
        background: url('/themes/chestnut/assets/dist/images/BOX.png') no-repeat;
        height: 15px;
        width: 15px;
        top: 2px;
        margin-right: 15px;
      }
    }

    .alert-text {
      display: block;
      font-size: 16px !important;
      color: #fff;
      font-weight: 400;
      line-height: 20px;
      text-transform: none;
    }

    .alert-btn {
      display: none;

      @media only screen and (min-width: 768px) {
        position: absolute;
        right: 70px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        text-transform: uppercase;
        font-weight: 600;
        color: #fff;
        border: 1px solid #fff;
        padding: 15px 20px;
      }
    }
  }

  .alert-nav {
    top: 25px !important;
    left: 20px !important;

    @media only screen and (min-width: 768px) {
      top: 50%;
      left: auto !important;
      right: 130px !important;
    }

    .alert-prev,
    .alert-next {
      color: #000 !important;

      &:hover,
      &:focus {
        color: #ffffff !important;
        outline: none;
      }
    }
  }
}

.alert-close {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 30px;
  width: 50px;
  height: 50px;
  text-align: right;
  cursor: pointer;
  z-index: 999;

  @media only screen and (min-width: 768px) {
    right: 80px;
    height: 100%;
  }

  &:before {
    content: '\f00d' !important;
    position: relative;
    font-family: 'Font Awesome 5 Pro';
    font-size: 16px;
    font-weight: 900;
    color: #000;
  }

  &:hover,
  &:focus {
    outline: none;

    &:before {
      color: #ffffff;
    }
  }
}