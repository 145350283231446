$nav-subpage-background-color: $lrs-secondary;
$nav-subpage-li1-color: $white;
$nav-subpage-a1-color: $white;
$nav-subpage-li2-background-color: $white;
$nav-subpage-a2-color: $white;
$nav-subpage-a2-selected-border-color: $white;

.nav-subpage {
  position: relative;
  z-index: 40;
  padding: 15px 15px 18px;
  width: 100%;
  background-color: $nav-subpage-background-color;
  background-position: top;

// background-image: url('/themes/LRS/assets/dist/images/departments-shadow.png');
  background-repeat: repeat-x;
  box-shadow: 0 0 4px rgba($black, 0.6);

@include clearfix();

@include respond-to(960px) {
    padding: 18px 25px;
    background-color: rgba($nav-subpage-background-color, 0.95);
  }

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: block;
    background:    -moz-linear-gradient(top, rgba(0,167,225,0) 0%, rgba(0,167,225,0) 50%, rgba(0,167,225,0.4) 100%);
    background: -webkit-linear-gradient(top, rgba(0,167,225,0) 0%,rgba(0,167,225,0) 50%,rgba(0,167,225,0.4) 100%);
    background:         linear-gradient(to bottom, rgba(0,167,225,0) 0%,rgba(0,167,225,0) 50%,rgba(0,167,225,0.4) 100%);
    content: '';
    opacity: 0.95;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0000a7e1', endColorstr='#6600a7e1',GradientType=0 );
  }

  h2 {
    display: block;
    margin: 0;
    color: #ffffff;
    list-style: none;
    font-weight: 700;
    font-size: 18px;
    font-family: $primary-font;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, .35);
    letter-spacing: 1px;
  }

  .ul1 {
    margin: 0;
    padding: 0;
    list-style: none;

    .li1 {
      margin: 0;
      color: $nav-subpage-li1-color;
      list-style: none;
      text-shadow: 0 0 4px rgba($black,0.5);
      font-weight: 600;
      font-size: 1.25rem;
      font-family: $primary-font;

      .a1 {
        color: $nav-subpage-a1-color;
        cursor: default;
      }
    }
  }

  .ul2 {
    display: none;

@include respond-to(960px) {
      display: block;
      overflow: hidden;
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .li2 {
      position: relative;
      float: left;
      padding: 0 20px;
      text-shadow: 0 0 4px rgba($black,0.5);
      font-weight: 400;
      font-family: $primary-font;

      &:after {
        position: absolute;
        top: 9px;
        right: 0;
        display: block;
        width: 2px;
        height: 14px;
        background: rgba($nav-subpage-li2-background-color,0.25);
        content: '';
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border-right: none;

        &:after {
          display: none;
        }
      }
    }

    .a2 {
      display: block;
      padding-top: 7px;
      padding-bottom: 7px;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      color: $nav-subpage-a2-color;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 0.9375rem;
      text-shadow: 1px 1px 5px rgba(0, 0, 0, .35);
      letter-spacing: 1px;
      font-weight: 600;

      &.selected,
      &.active {
        border-bottom: 2px solid rgba($nav-subpage-a2-selected-border-color, 0.25);
      }
    }
  }
}

.careers-app .nav-subpage {
  background: $nav-subpage-background-color;
}

.lrs-campus-expansion,
.divisions,
.disclaimers,
.attributions,
.privacy,
.vendors,
.news,
.subscription-center,
.unsubscribe {
  .nav-subpage {
    .ul2 {
      display: none;
    }
  }
}

.subscription-center,
.unsubscribe {
  .nav-subpage {
    box-shadow: none;
  }
}
