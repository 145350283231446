$page-footer-border-top: $silver-chalice;
$page-footer-background-color: $white;
$page-footer-a-color: $dove-gray;
$page-footer-secondary-content-background-color: $concrete;
$page-footer-nav-li-border-color: $alto;
$page-footer-nav-a-border-color: $alto;
$page-footer-social-nav-a-color: $dusty-gray;
$errorpage-page-footer: darken(desaturate(adjust-hue($lrs-secondary, -0.0710), 0.0332), 14.7059);

.page-footer {
  position: relative;
  width: 100%;
  border-top: solid 5px $page-footer-border-top;
  padding: 20px 0 0;
  background: $page-footer-background-color;
  font-family: $primary-font;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  z-index: 1;

  @include respond-to(640px) {
    position: fixed;
    bottom: 0;
    padding: 0 15px;
    overflow: hidden;
  }

  @include respond-to(1070px) {
    padding: 0 25px;
  }

  a {
    color: $page-footer-a-color;
    text-decoration: none;
  }

  .primary-content {
    display: block;
    text-align: center;

    @include respond-to(640px) {
      float: left;
      padding: 15px 0 16px;
      text-align: left;
    }

    @include respond-to(1070px) {
      padding: 6px 0;
    }
  }

  .secondary-content {
    display: block;
    background: $page-footer-secondary-content-background-color;
    text-align: center;

    @include respond-to(640px) {
      display: block;
      float: right;
      padding: 22px 0 23px;
      background: none;
      text-align: right;
    }

    @include respond-to(1070px) {
      padding: 4px 0;
    }
  }

  .copyright {
    display: block;

    em {
      display: block;
      font-style: normal;

      @include respond-to(640px) {
        display: inline;
      }
    }

    @include respond-to(1070px) {
      display: inline-block;
    }
  }

  .footer-nav {
    display: block;
    margin: 11px 0 16px  0;
    padding: 0;
    list-style: none;

    @include respond-to(640px) {
      margin: 0;
    }

    @include respond-to(1070px) {
      display: inline-block;
      margin-left: 6px;
    }

    li {
      @include respond-to(640px) {
        display: inline-block;
        padding: 4px 0;
      }

      &:first-child a {
        @include respond-to(640px) {
          padding-left: 0;
          border-left: 0;
        }

        @include respond-to(1070px) {
          padding-left: 8px;
          border-left: 2px solid $page-footer-nav-li-border-color;
        }
      }
    }

    a {
      display: block;
      padding: 4px 0;

      @include respond-to(640px) {
        border-left: 2px solid $page-footer-nav-a-border-color;
        padding: 0 8px;
        line-height: 12px;
      }
    }
  }

  .social-media {
    margin: 0;
    padding: 13px 0;
    list-style: none;

    @include respond-to(640px) {
      padding: 0;
    }

    li {
      display: inline-block;

      &:first-child a {
        @include respond-to(640px) {
          padding-left: 0;
        }
      }

      &:last-child a {
        @include respond-to(640px) {
          padding-right: 0;
        }
      }
    }

    a {
      display: block;
      padding: 0 7px;
      color: $page-footer-social-nav-a-color;
      font-size: 16px;
      text-decoration: none;
    }
  }
}
