$nav-global-a-color: $lrs-primary;
$nav-global-a-before-background-color: $team-coco;

.nav-global {
  position: absolute;
  top: 21px;
  right: 165px;
  display: none;

@include respond-to(640px) {
    right: 195px;
    display: block;
  }

@include respond-to(1350px) {
    top: 29px;
    right: 175px;
  }

  a {
    position: relative;
    display: block;
    float: left;
    padding: 0;
    height: 35px;
    color: $nav-global-a-color;
    text-decoration: none;
    font-weight: 700;
    font-size: 12px;
    line-height: 35px;

     + a {
      margin-left: 12px;
      padding-left: 12px;

      &:before {
        position: absolute;
        top: 12px;
        left: 0;
        display: block;
        width: 1px;
        height: 12px;
        background: $nav-global-a-before-background-color;
        content: '';
      }
    }

    &:hover {
      text-decoration: underline;
    }

    &.login {
      padding-left: 40px;

      &:before {
        position: absolute;
        top: 8px;
        left: 8px;
        display: block;
        width: 25px;
        height: 18px;
        background-image: url('/themes/LRS/assets/dist/images/lrs-corporate-diamond-light-gray.svg');
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        content: '';
      }
    }
  }

  .fixed & {
    top: 7px;
  }
}

.eeo {
  .nav-global,
  .fixed .nav-global {
    right: 15px;

@include respond-to(640px) {
      right: 15px;
    }

@include respond-to(1350px) {
      right: 15px;
    }
  }
}
