$site-search-input-color: $gray;
$site-search-input-background-color: $gallery;
$site-search-submit-background-color: $nobel;
$site-search-submit-hover-background-color: $gray;
$fixed-site-search-submit-background-color: $gallery;

.site-search {
  position: absolute;
  top: 21px;
  right: 10px;

@include respond-to(1350px) {
    top: 30px;
    right: 21px;
  }

  .classic-search {
    position: relative;
    display: block;
    float: right;
    margin-right: 15px;

    input {
      box-sizing: border-box;
      margin: 0 -3px 0 0;
      padding: 0 27px 0 18px;
      width: 108px;
      height: 35px;
      // outline: none;
      border: 0;
      border-radius: 18px 0 0 18px;
      background: $site-search-input-background-color;
      box-shadow: none;
      color: $site-search-input-color;


@include respond-to(640px) {
        width: 138px;
      }

@include respond-to(1350px) {
        width: 118px;
      }
    }
  }

  .btn-search {
    position: absolute;
    top: -2px;
    right: -15px;
    display: inline-block;
    margin-right: 0;
    width: 39px;
    height: 39px;
    border-radius: 50%;
    background-color: $site-search-submit-background-color;
    background-image: url('/themes/LRS/assets/dist/images/search-glass.png');
    background-position: 10px 9px;
    background-repeat: no-repeat;
    text-indent: -10000px;

    &:hover {
      background-color: $site-search-submit-hover-background-color;
    }
  }

  .fixed & {
    top: 7px;

    .search-submit {
      top: 0;
      width: 35px;
      height: 35px;
      background-color: $fixed-site-search-submit-background-color;
      background-image: url('/themes/LRS/assets/dist/images/search-glass-dk.png');
      background-position: 7px 8px;
    }
  }
}

// Search results
.ModSearchResultsC {
  .dnnSearchBoxPanel {
    display: none !important;
  }

  .dnnSearchResultPanel {
    display: none !important;
  }
}

.search-results-search {
  display: none;
}

.no-results {
  font-size: 20px;
  margin: 15px 0;
  color: #D10000;
}
