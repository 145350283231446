$iebar-bg: #ffc410;
$iebar-border: #eab105;
$iebar-txt-top: #755603;
$iebar-txt-bottom: #4c3906;
$iebar-txt-bottom-hover: #222;

.ie-bar {
  width: 100%;
  padding: 15px;
  text-align: center;
  background: $iebar-bg;
  border-bottom: 5px solid $iebar-border;
  box-shadow: 0 2px 0 0 rgba($black, 0.1);

  p {
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: $iebar-txt-top;
    font-family: $primary-font;
    font-style: normal;

    b,
    strong {
      font-weight: 700;
    }

    a {
      color: $iebar-txt-bottom;
    }
  }
}
