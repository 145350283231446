$hp-jobs-base-color: $lrs-primary;
$hp-jobs-heading-color: $white;
$hp-jobs-heading-background-color: $hp-jobs-base-color;
$hp-jobs-background-color: darken(saturate(adjust-hue($hp-jobs-base-color, -0.3889), 0.6838), 3.5294);
$hp-jobs-title-color: $white;
$hp-jobs-subheading-color: lighten(saturate(adjust-hue($hp-jobs-base-color, -1.4638), 27.8788), 61.5686);
$hp-jobs-description-color: $white;
$hp-jobs-read-more-color: lighten(saturate(adjust-hue($hp-jobs-base-color, -1.4638), 27.8788), 61.5686);
$hp-jobs-read-more-hover-color: $white;
$hp-jobs-read-more-hover-background-color: lighten(saturate(adjust-hue($hp-jobs-base-color, -1.4638), 27.8788), 61.5686);

.hp-jobs {
  background: $hp-jobs-background-color;

  @include respond-to(768px) {
    float: left;
    width: calc((((100% - 10px) / 3) * 1) - 1px);
    margin: 0;
  }

  @include respond-to(1024px) {
    width: calc(((100% - 10px) / 2) - 1px);
  }

  @include respond-to(1300px) {
    width: 280px;
  }

  @include respond-to(1920px) {
    width: 280px;
  }

  .hp-widget-inner {
    position: relative;
    padding-bottom: 60px;
  }

  h3 {
    position: relative;
    display: block;
    padding: 41px 18px;
    margin: 0 0 28px;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: $hp-jobs-heading-color;
    text-align: center;
    text-transform: uppercase;
    background: $hp-jobs-heading-background-color;
  }

  .job-details {
    padding-top: 10px;
    padding-bottom: 54px;
  }

  .job-heading {
    display: block;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 2px;
    color: $hp-jobs-subheading-color;
    text-align: center;
    text-transform: uppercase;
  }

  .job-title {
    display: block;
    padding: 0 36px;
    margin: 4px 0 18px 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color: $hp-jobs-title-color;
    text-align: center;
  }

  .job-description {
    display: block;
    max-width: 460px;
    padding: 0 36px;
    margin: 0 auto 36px;
    font-size: 15px;
    line-height: 18px;
    color: $hp-jobs-description-color;
    text-align: center;
  }

  .read-more {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 60px;
    font-size: 13px;
    font-weight: 700;
    line-height: 60px;
    color: $hp-jobs-read-more-color;
    text-align: center;
    text-transform: uppercase;
    transition: background-color 0.2s ease-out;

    &:hover {
      color: $hp-jobs-read-more-hover-color;
      background: rgba($hp-jobs-read-more-hover-background-color, 0.05);
    }
  }

  /*& .consulting {
    background: #0066a6;

    h3 {
      background: #0073ba;

      &:after {
        background-color: #0073ba;
        background-image: url('../svg/lrs-consulting-services.svg');
        background-size: 38px 31px;
        border-color: #0066a6;
      }
    }

    .job-heading,
    .read-more {
      color: #b3d7ed;
    }
  }

  & .capella {
    background: #595959;

    h3 {
      background: #737373;

      &::after {
        background-color: #737373;
        background-image: url('../svg/capella.svg');
        background-size: 24px 24px;
        border-color: #595959;
      }
    }

    .job-heading,
    .read-more {
      color: #d5d5d5;
    }
  }

  & .dys {
    background: #023162;

    h3 {
      background: #003d7d;

      &::after {
        background-color: #003d7d;
        background-image: url('../svg/dys.svg');
        background-size: 23px 27px;
        border-color: #023162;
      }
    }

    .job-heading,
    .read-more {
      color: #b3c5d8;
    }
  }

  & .output-management {
    background: #4d6199;

    h3 {
      background: #5f74ad;

      &::after {
        background-color: #5f74ad;
        background-image: url('../svg/lrs-output-management.svg');
        background-size: 26px 24px;
        border-color: #4d6199;
      }
    }

    .job-heading,
    .read-more {
      color: #dfe4f2;
    }
  }

  & .education {
    background: #5b7c8c;

    h3 {
      background: #7796a6;

      &::after {
        background-color: #7796a6;
        background-image: url('../svg/lrs-education.svg');
        background-size: 35px 20px;
        border-color: #5b7c8c;
      }
    }

    .job-heading,
    .read-more {
      color: #dce7ed;
    }
  }

  & .pen-gold {
    background: #101a61;

    h3 {
      background: #1c2776;

      &::after {
        background-color: #1c2776;
        background-image: url('../svg/pension-gold.svg');
        background-size: 23px 21px;
        border-color: #101a61;
      }
    }

    .job-heading,
    .read-more {
      color: #bbbfd6;
    }
  }

  & .it-solutions {
    background: #bf2638;

    h3 {
      background: #d63347;

      &::after {
        background-color: #d63347;
        background-image: url('../svg/lrs-it-solutions.svg');
        background-size: 28px 25px;
        border-color: #bf2638;
      }
    }

    .job-heading,
    .read-more {
      color: #f3c2c8;
    }
  }

  & .network-support {
    background: #380a59;

    h3 {
      background: #49176d;

      &::after {
        background-color: #49176d;
        background-image: url('../svg/lrs-network-support.svg');
        background-size: 27px 20px;
        border-color: #380a59;
      }
    }

    .job-heading,
    .read-more {
      color: #c9bad3;
    }
  }

  & .web-solutions {
    background: #333;

    h3 {
      background: #444;

      &::after {
        background-color: #444;
        background-image: url('../svg/lrs-web-solutions.svg');
        background-size: 38px 31px;
        border-color: #333;
      }
    }

    .job-heading,
    .read-more {
      color: #a1a1a1;
    }
  }

  & .corporate {
    background: #0081a6;

    h3 {
      background: #179ec0;

      &::after {
        background-color: #179ec0;
        background-image: url('../svg/lrs-diamond.svg');
        background-size: 38px 31px;
        border-color: #0081a6;
      }
    }

    .job-heading,
    .read-more {
      color: #b3d7ed;
    }
  }*/
}


.hp-jobs {
  display: none !important;
}