.branding {
  float: left;
  overflow: hidden;

  .logo {
    display: block;
    width: 142px;
    height: 51px;

    @include respond-to(1350px) {
      width: 175px;
      height: 63px;
    }

    img {
      display: block;
    }
  }
}

.fixed .branding {

  .logo {
    width: 108px;
    height: 39px;

    @include respond-to(1350px) {
      width: 108px;
      height: 39px;
    }
  }
}
