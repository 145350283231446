#Page {
  .Normal, .NormalDisabled, .NormalDeleted {
    /*color:auto;*/
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}

.button, button {
  padding: 0;
}

/*fix for DNN controlbar*/
#ControlBar {
  height: 53px !important;

  *, *:before, *:after {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
}

#ControlNav > li > a,
#ControlActionMenu > li > a,
#ControlEditPageMenu > li > a {
  /*height: 43px !important;*/
}

#ControlBar_CategoryList {
  *, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

/*end of fix for DNN controlbar*/

h1, h2, h3, h4, h5, h6 {
  display: block;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  margin: 0;
}

blockquote {
  border-left: none;

  p {
    margin: inherit;
  }
}

/*p {margin: inherit;}*/

ul, ol {
  margin-bottom: inherit;
  margin-left: inherit;
}

.invisible {
  visibility: inherit;
}

a {
  transition: color 0.2s ease;
  text-decoration: none;
}

#Page {
  min-width: 320px;
  height: 100%;
  min-height: 100%;

  .column, .columns {
    padding: 0;

    @media #{$medium-up} {
      padding-left: 0.3125rem;
      padding-right: 0.3125rem;
    }
  }

  .row {
    max-width: 100%;
  }

  .row .row {
    margin: 0;
    max-width: none;
    width: auto;
  }
}

.dnnFormItem .wsLabel {
  font-weight: bold !important;
}


ul.dnnActions {
  padding: 0 !important;
  margin: 0 !important;
}