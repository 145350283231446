$hp-widget-view-all-color: $lrs-accent;
$hp-widget-view-all-background-color: #001645;
$hp-widget-view-all-hover-background-color: $mine-shaft;

.home-content {
  font-family: $primary-font;
  @include respond-to(1024px) {
    overflow: hidden;
    margin-bottom: 51px;
    padding-bottom: 0;
  }
  @include respond-to(640px) {
    padding: 0 10px 50px;
  }

  .hp-widget {
    display: block;

    h2 {
      display: block;
      margin: 0;
      padding: 0;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 400;
      font-size: 20px;
      line-height: 45px;
      @include respond-to(640px) {
        padding-right: 25px;
        padding-left: 25px;
        text-align: left;
      }

      .Head {
        margin: 0;
      }
    }

    .view-all {
      display: block;
      background: $hp-widget-view-all-background-color;
      color: $hp-widget-view-all-color;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: bold;
      font-size: 13px;
      line-height: 60px;

      &:hover {
        background: rgba($hp-widget-view-all-hover-background-color, 0.85);
      }
    }
  }

  .primary-content {
    @include respond-to(1920px) {
      width: calc((100% - 860px) - 1px);
    }
    @include respond-to(1300px) {
      width: calc(((100% - 10px) * 0.667) - 1px);
    }
    @include respond-to(1024px) {
      float: left;
      margin-right: 10px;
      width: calc(((100% - 10px) * 0.50) - 1px);
    }
  }

  .secondary-content {
    @include respond-to(1920px) {
      width: 850px;
    }
    @include respond-to(1300px) {
      width: calc(((100% - 10px) * 0.333) - 1px);
    }
    @include respond-to(1024px) {
      float: left;
      width: calc(((100% - 10px) * 0.50) - 1px);
    }
    @include respond-to(768px) {
      overflow: hidden;
    }
  }
}

.lrs-expansion-container {
  margin: 60px 20px;

  a {
    &:hover {
      text-decoration: none;
    }
  }

  .lrs-expansion {
    display: block;
    padding: 20px 40px;
    width: 100%;
    height: 60px;
    background-color: rgba(#0099cc, 0.8);
    background-image: url(/themes/LRS/assets/dist/images/lrs-campus-expansion-background-2.png);
    background-position: center left;
    background-size: 258px 100%;
    background-repeat: no-repeat;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 2px;

    &:focus,
    &:hover {
      background-color: #0099cc;
    }

    @media only screen and (max-width: 768px) {
      height: auto;
      background-image: url(/themes/LRS/assets/dist/images/lrs-campus-expansion-background-mobile.png);
      background-position: center;
      background-size: cover;
      text-align: center;
    }

    span.expansion-blurb {
      margin-left: 10%;
      font-family: effra,sans-serif;

      @media only screen and (max-width: 1024px) {
        margin: 0;
      }

      @media only screen and (max-width: 768px) {
        display: block;
      }
    }

    span.look {
      float: right;
      font-family: effra,sans-serif;

      @media only screen and (max-width: 768px) {
        // position: relative;
        // top: 10px;
        // float: none;
        display: none;
      }

      &:after {
        position: relative;
        top: 5px;
        left: 10px;
        padding: 15px 20px;
        background: url(/themes/LRS/assets/dist/images/campus-arrow.png) no-repeat;
        content: '';
      }
    }

    a {
      color: #ffffff;
    }
  }

  span.look-mobile {
    display: none;
    padding: 20px;
    height: 64px;
    background-color: #0099cc;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;

    @media only screen and (max-width: 768px) {
      display: block;
    }

    &:after {
      position: relative;
      top: 5px;
      left: 10px;
      padding: 15px 20px;
      background: url(/themes/LRS/assets/dist/images/campus-arrow.png) no-repeat;
      content: '';
    }
  }
}
