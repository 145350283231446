$form-section-border-top-color: $darkdark-alto;
$form-label-color: $lightest-tundora;
$form-control-background-color: $white;
$form-control-border-color: $darkdark-alto;
$form-heading-h1-color: $lrs-primary;
$submit-button-color: $lrs-primary;
$submit-button-border-color: $darkest-alto;
$submit-button-hover-border-color: $lrs-primary;
$dnnlabel-color: $lightest-tundora;

form *[data-layout="true"] {
  margin: 0 auto;
  max-width: none !important;

  .columnContainer {
    padding: 0 0 30px 0 !important;
    margin-bottom: 40px;
    width: 100% !important;
    border-bottom: 2px solid #cfcfcf;
  
    @include respond-to(768px) {
      width: calc(50% - 40px) !important;
      margin-right: 80px;
  
      &:nth-child(odd) {
        margin-right: 0;
      }
    }
  
    p {
      font-size: 16px !important;
      color: #222222 !important;
      margin-bottom: 5px !important;
  
      strong {
        font-size: 30px !important;
        line-height: 30px !important;
        font-weight: 600 !important;
      }
  
      em {
        display: block;
        margin-bottom: 20px;
      }
    }

    .lp-form-field {
      margin-bottom: 20px;
    }

    table {
      background: transparent !important;
      border: none !important;
    }

    table tr:nth-of-type(even) {
      background: transparent !important;
    }

    table tr td {
      display: block !important;
      padding: 0 !important;
    }

  }

  div[data-section="true"]:nth-child(3) {
    .columnContainer { 
      &:nth-child(3) {
        border-bottom: none !important;
      }

      @include respond-to(768px) {
        border-bottom: none !important;
      }
    }
  }

  .divUnsubscribeAll {
    background-color: #d4d4d5 !important;
    padding: 30px 60px 10px 60px !important;
    border-radius: 15px !important;
    margin-bottom: 40px;

    .columnContainer {
      width: 100% !important;
      border-bottom: none !important;
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }

    p {
      margin-bottom: 15px !important;

      strong {
        font-size: 24px !important;
        line-height: 30px !important;
        font-weight: 700 !important;
      }
    }
  }

  .button-container {
    .columnContainer {
      width: 100% !important;
      border-bottom: none !important;
      margin-right: 0;
    }
  }
}

.unsubscribe form *[data-layout=true] .columnContainer {
  border-bottom: none !important;

  p {
    margin-bottom: 15px !important;
  }
}

.lp-required {
  font-size: 14px;
  position: relative;
  top: -4px;
  left: 2px;
}

.form-container,
fieldset {
  border: none;

  .form-section {
    padding: 30px 0;

    & + .form-section {
      border-top: 3px solid $form-section-border-top-color;
    }
  }

  .form-row {
    display: block;
    clear: both;

    & + .form-row {
      padding-top: 30px;
    }
  }

  .form-group {
    display: block;
    width: 100%;
    @include respond-to(768px) {
      float: left;
      padding-right: 10px;
    }

    & + .form-group {
      padding-top: 15px;
      @include respond-to(768px) {
        padding-top: 0;
        // padding-left: 10px;
      }
    }

    &.span-one {
      @include respond-to(768px) {
        width: calc(((100% / 12) * 1) - 1px);
      }
    }

    &.span-two {
      @include respond-to(768px) {
        width: calc(((100% / 12) * 2) - 1px);
      }
    }

    &.span-three {
      @include respond-to(768px) {
        width: calc(((100% / 12) * 3) - 1px);
      }
    }

    &.span-four {
      @include respond-to(768px) {
        width: calc(((100% / 12) * 4) - 1px);
      }
    }

    &.span-five {
      @include respond-to(768px) {
        width: calc(((100% / 12) * 5) - 1px);
      }
    }

    &.span-six {
      @include respond-to(768px) {
        width: calc(((100% / 12) * 6) - 1px);
      }
    }

    &.span-seven {
      @include respond-to(768px) {
        width: calc(((100% / 12) * 7) - 1px);
      }
    }

    &.span-eight {
      @include respond-to(768px) {
        width: calc(((100% / 12) * 8) - 1px);
      }
    }

    &.span-nine {
      @include respond-to(768px) {
        width: calc(((100% / 12) * 9) - 1px);
      }
    }

    &.span-ten {
      @include respond-to(768px) {
        width: calc(((100% / 12) * 10) - 1px);
      }
    }

    &.span-eleven {
      @include respond-to(768px) {
        width: calc(((100% / 12) * 11) - 1px);
      }
    }

    &.span-twelve {
      @include respond-to(768px) {
        width: calc(((100% / 12) * 12) - 1px);
      }
    }
  }

  // .form-label,
  // label {
  //   display: block;
  //   margin-bottom: 4px;
  //   color: $form-label-color;
  //   font-weight: 800;
  //   font-size: 20px;
  // }

  // .form-control,
  // input,
  // select,
  // textarea {
  //   display: block;
  //   padding: 5px 10px 6px;
  //   width: 100%;
  //   border: 1px solid $form-control-border-color;
  //   background: $form-control-background-color;
  // }

  input[type='text'].form-control {
    font-size: 18px;
  }

  textarea.form-control {
    height: 60px;

    &:focus,
    &:active {
      height: 160px;
    }
  }

  .form-heading {
    padding-bottom: 30px;

    h1 {
      display: block;
      clear: both;
      margin-bottom: 0;
      color: $form-heading-h1-color;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 30px;
      line-height: 30px;
    }
  }

  .submit-row {
    display: block;

    .submit-button {
      position: relative;
      display: block;
      margin: 20px 0;
      padding: 13px 0 16px;
      width: 100%;
      border-width: 4px 0;
      border-style: solid;
      border-color: $submit-button-border-color;
      background: none;
      color: $submit-button-color;
      text-align: left;
      font-weight: 600;
      font-size: 22px;

      &:hover {
        border-color: $submit-button-hover-border-color;
      }
    }
  }

  .dnnLabel {
    display: block;
    float: none;
    margin: 0 0 4px;
    padding: inherit;
    width: 100%;
    color: $dnnlabel-color;
    text-align: left;
    font-weight: 800;
    font-size: 20px;
  }
}

input[type='submit'],
.lp-form-button {
  position: relative;
  display: block;
  height: auto !important;
  margin: 20px 0 !important;
  padding: 20px 0 22px !important;
  width: 100%;
  border: 2px solid #cfcfcf !important;
  border-left: none !important;
  border-right: none !important;
  background: none;
  background-color: transparent !important;
  color: $submit-button-color !important;
  text-align: left;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  border-radius: 0 !important;

  &:hover {
    border-color: $submit-button-hover-border-color !important;
  }
}

input[type='checkbox'],
input[type='radio'] {
  float: left;
  margin-top: 5px;
  margin-right: 10px;
  width: auto;
}

.content-form a {
  font-weight: 800;
}

input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;

  + label {
    position: relative;
    display: inline-block;
    padding-left: 35px;
    font-weight: normal;
    margin-bottom: 25px;
    font-size: 18px !important;
    color: #222222;

    &:before,
    &:after {
      position: absolute;
      display: inline-block;
      content: '';
    }

    &:before {
      background: white;
      top: 0px;
      left: 0;
      width: 25px;
      height: 25px;
      border: none;
      border-radius: 3px;
      box-shadow: 0 0 10px rgba(0,0,0,.15);
    }

    &:after {
      top: 0;
      left: 0;
      content: none;
    }
  }

  &:checked + label {
    &:after {
      content: '\f00c';
      color: #17468f;
      font-family: 'FontAwesome';
      font-weight: 900;
      font-size: 18px;
      left: 3px;
      top: 2px;
    }

    &:before {
      content: '';
      background: white;
    }
  }

  &:focus + label {
    &:before {
      outline: 5px auto -webkit-focus-ring-color;
    }
  }
}

.form-label,
label {
  display: block;
  margin-bottom: 4px;
  color: $form-label-color;
  font-weight: 800;
  font-size: 20px;
}

.form-control,
input,
select,
textarea {
  display: block;
  padding: 5px 10px 6px;
  width: 100%;
  border: 1px solid $form-control-border-color;
  background: $form-control-background-color;
}

input[type='radio'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;

  + label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 30px;

    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      background: white;
      font-weight: 500;
      left: 0;
      color: blue;
      font-size: 20px;
      height: 25px;
      width: 25px;
      top: 0;
      border: 1px solid $form-control-border-color;
      border-radius: 25px;
    }
  }

  &:checked + label {
    &:after {
      content: '\f111';
      color: #17468f;
      font-family: 'FontAwesome';
      font-weight: 900;
      font-size: 18px;
      left: 3px;
      top: 2px;
      position: absolute;
    }
  }

  &:focus + label {
    &:before {
      outline: 5px auto -webkit-focus-ring-color;
    }
  }
}

ul {
  padding: 0;

  li {
    &:before {
      display: none;
    }
  }
}